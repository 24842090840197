// 创建一个文件，暂且命名为 context.tsx，导出 createContext() 的返回值
import React, { createContext, useState } from 'react';

import { apiAuth } from './utils/feishu-api-auth';
import { isDev, isLocal } from '@/config/index';
import type {
  IAuthClient,
  IUserInfo,
} from '@/feishu/typing';
import { message } from 'antd';
import { useQueryParams } from '@/hooks/useQueryParams';
import console from './utils/debug';

export const feishuConfig = {
  authClient: {} as IAuthClient,
};

interface IContext {
  userInfo: IUserInfo;
  initialized: boolean;
  logout: () => void;
}

export const useFeishu = (): IContext => {
  const feishuContext = React.useContext(FeishuContext);
  return feishuContext;
};

export const FeishuContext = createContext<IContext>({
  userInfo: {
    name: '',
  },
  initialized: false,
  logout: () => undefined,
});

const defaultLoginUrl = '/';
interface IFeishuProviderProps {
  children: React.ReactNode
  authClient: IAuthClient
}
export const FeishuProvider: React.FC<IFeishuProviderProps> = (props) => {
  const { children, authClient } = props;

  const {
    loginUrl = defaultLoginUrl,
    checkIfLogin,
    fetchAppId,
    fetchCallback,
  } = authClient || {};

  feishuConfig.authClient = authClient;

  const [userInfo, setUserInfo] = useState<IUserInfo>({});
  const [initialized, setInitialized] = useState<boolean>(false);
  const handleInfo = (isInitialized: boolean, info: IUserInfo) => {
    setInitialized(isInitialized);
    setUserInfo(info);
  };

  const onLogin = () => {
    console.info('main isDev', isDev, location.pathname);
    checkIfLogin().then(async res => {
      const { code, data } = res || {};
      console.info('checkInfLogin', JSON.stringify(res), 'location.href', location.href);
      // 规避接口返回 code 值不同系统表现不一致的问题
      if (code == null || !data) {
        // 接口返回 html 的情况
        message.error('登录失败！');
        handleInfo(true, {});
        return;
      }
      const { user_info: userInfo, is_login: isLogin } = data;
      /**
       * 刷新页面后的判断，
       * 若登录了，则直接获取用户信息。
       * 若未登录，则走登录流程。
       */
      if (isLogin) {
        handleInfo(true, userInfo);
        return;
      } else {
        console.info('测试当前的 pathname 是什么 location.pathname !== loginUrl',
          location.pathname !== loginUrl, 'location.pathname', location.pathname);
        if (location.pathname !== loginUrl) {
          const { pathname, search } = location;
          console.info('Current location is', location, JSON.stringify(location));
          const oldUrl = `${pathname}`;
          console.info('oldUrl is', oldUrl);
          const url = `${loginUrl}?redirect=${oldUrl}&queryStr=${search}`;
          console.info('redirect url is', url);
          location.href = url;
          // 用下面的方式不跳转？
          // his.push(url);
          return;
        }
      }

      const h5sdk = window.h5sdk;
      // 非飞书客户端环境下，使用浏览器网页登录。
      if (!h5sdk) {
        // TODO: Testing
        const queryParams: { code: string } = useQueryParams();
        const { code } = queryParams;
        console.info('!h5sdk queryParams', queryParams);
        if (code) {
          const userInfo = await fetchCallback({ code });
          console.info('!h5sdk getUserInfo succeed', userInfo);
          if (userInfo) {
            handleInfo(true, userInfo);
            return;
          }
        }

        // setTimeout(() => {
        //   setInitialized(true);
        // }, 10000);
        /**
         * 飞书浏览器网页登录：https://open.feishu.cn/document/uYjL24iN/ukTO4UjL5kDO14SO5gTN#9f534005
         */
        fetchAppId().then(res => {
          console.info('fetchAppId res', res);
          const APPID = res.appid;
          const REDIRECT_URI = encodeURIComponent(location.href);
          // const REDIRECT_URI = encodeURIComponent('https://wts.cla-dev.mmtdev.com/fill-report/demand-report');
          const STATE = 'RANDOMSTATE';
          const loginPath = 'https://open.feishu.cn/open-apis/authen/v1/index';
          const url = `${loginPath}?redirect_uri=${REDIRECT_URI}&app_id=${APPID}&state=${STATE}`;
          console.info('重定向 url', url);
          // 跳转到 https://open.feishu.cn 的登录认证页面，跳转回来 URL 上会带 code 参数，如下：
          // https://wts.cla-dev.mmtdev.com/?code=73fq1dcd06fc40748a342161ab80a4d8&state=RANDOMSTATE
          location.href = url;
        });
      } else {
        apiAuth((userInfo, lang) => {
          console.info('apiAuth userInfo', userInfo);
          console.info('apiAuth lang', lang);
          if (!userInfo?.name) {
            window.location.reload();
            return;
          }
          handleInfo(true, userInfo);
        });
      }
    }).catch(err => {
      // 接口 502 的情况。
      message.error('登录失败！Error is:', err);
      console.error('Error is', err);
      handleInfo(true, {});
    });
  };
  React.useEffect(() => {
    onLogin();
  }, []);

  const logout = async () => {
    // 酌情使用
    // await onLogout();
    // window.location.reload();
  };

  return (
    <FeishuContext.Provider
      value={{
        userInfo,
        initialized,
        logout,
      }}
    >
      {children}
    </FeishuContext.Provider>
  );
};
